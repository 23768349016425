import { cssTransition } from 'react-toastify';
import api from './api';
import { PAYMENT_STATUS, SESSION_STATUS } from './../constants';
import { PRIMARY_COLOR } from 'theme';

const updateCookie = (data, cookies, setCookies) => {
  let expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 7 * 86400000);
  const options = { expires: expiryDate, path: '/' };
  let store = { ...cookies.store, ...data };
  setCookies('store', JSON.stringify(store), options);
};

const updateLocationInCookie = (location, cookies, setCookies) => {
  let currentDate = new Date();
  const data = {
    lastpageVisited: location,
    lastVisit: currentDate.toUTCString(),
  };
  updateCookie(data, cookies, setCookies);
};

const hhmmss = (secs) => {
  if (!secs) {
    return `00h 00m`;
  }

  var minutes = Math.floor(secs / 60);
  secs = secs % 60;
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (hours > 0) {
    return `${pad(hours)}h ${pad(minutes)}m`;
  } else {
    return `00h ${pad(minutes)}m`;
  }
};

const pad = (num) => {
  return ('0' + num).slice(-2);
};

//TODO use library to do this
//TODO currency symbol placment
const convertCurrencyFromText = (text = '') => {
  if (text.toLowerCase() === 'usd') {
    return '$';
  } else if (text.toLowerCase() === 'eur') {
    return '€';
  } else if (text) {
    return text.toUpperCase();
  }
  return '$';
};

const rad = (x) => {
  return (x * Math.PI) / 180;
};

const ToastZoomTransition = () => {
  return cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    duration: 1750,
    appendPosition: false,
    collapse: true,
    collapseDuration: 800,
  });
};

const detectActiveSession = async ({ sessionId, paymentId }) => {
  if (localStorage.getItem('clear_cookies')) {
    localStorage.removeItem('clear_cookies');
    return {};
  }

  if (!sessionId) {
    return {};
  }

  try {
    const response_session = await api.get(
      null,
      `v1/public/sessions/${sessionId}`
    );
    const session = response_session.result;

    if (
      session &&
      session?.status !== SESSION_STATUS.ENDED &&
      session?.status !== SESSION_STATUS.CANCELED
    ) {
      if (session.payment._id === paymentId) {
        if (session.payment.status === PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD) {
          return {};
        }
        return { ...session, type: 'session' };
      }
      return { ...session, type: 'session' };
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

const getMinimumAmount = (rate) => {
  let minimum = rate?.min_price?.incl_vat?.toFixed(2) ?? 0;

  rate.elements.forEach((eachElement) => {
    eachElement.price_components.forEach((eachPriceComponent) => {
      if (eachPriceComponent.type === 'FLAT') {
        minimum = Math.max(minimum, eachPriceComponent.price);
      }
    });
  });

  return {
    amount: minimum,
    type: rate?.min_price?.incl_vat === minimum ? ' minimum' : ' Flat',
  };
};

const getRates = (rate, showFlat = false) => {
  const texts = [];

  if (!rate?.elements) return texts;

  rate.elements.forEach((eachElement) => {
    eachElement.price_components.forEach((eachPriceComponent) => {
      if (eachPriceComponent?.type === 'ENERGY') {
        texts.push(
          ` ${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}/kWh`
        );
      } else if (eachPriceComponent?.type === 'TIME') {
        texts.push(
          ` ${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}/hr`
        );
      } else if (eachPriceComponent?.type === 'PARKING_TIME') {
        texts.push(`After charge completion`);

        texts.push(
          ` ${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}/hr (${
            eachPriceComponent.grace_period
          } hr grace)`
        );
      } else {
      }
    });
  });

  return texts;
};

const getRatesWithType = (rate, showFlat = false) => {
  const texts = [];

  if (!rate?.elements) return texts;

  rate.elements.forEach((eachElement) => {
    eachElement.price_components.forEach((eachPriceComponent) => {
      if (eachPriceComponent?.type === 'ENERGY') {
        texts.push({
          text: ` ${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}/kWh`,
          type: 'While charging',
        });
      } else if (eachPriceComponent?.type === 'TIME') {
        texts.push({
          text: ` ${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}/hr`,
          type: 'While charging',
        });
      } else if (eachPriceComponent?.type === 'FLAT') {
        texts.push({
          text: `${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}`,
          type: 'Start Fee',
        });
      } else if (eachPriceComponent?.type === 'PARKING_TIME') {
        texts.push({
          text: ` ${convertCurrencyFromText(
            rate.currency
          )} ${eachPriceComponent?.price?.toFixed(2)}/hr (${
            eachPriceComponent.grace_period
          } hr grace)`,
          type: 'After charging stops',
        });
      } else {
      }
    });
  });

  return texts;
};

const isAuthenticated = () => {
  // Check whether the current time is past the
  // access token's expiry time
  let expiresAt = JSON.parse(localStorage.getItem('expires_at'));

  let status = new Date().getTime() < expiresAt;

  if (!status) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
  }

  return status;
};

const getQueryString = (params) => {
  var esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => esc(k) + '=' + esc(params[k]))
    .join('&');
};

const generateCode = (size = 4) => {
  return Math.round(Math.pow(36, size + 1) - Math.random() * Math.pow(36, size))
    .toString(36)
    .slice(1);
};

const stringToBoolean = (string) => {
  string = string.toString().toLowerCase();

  return string === 'true';
};

const getDefaultPaymentMethod = (id, data) => {
  return id && data ? data.find((eachData) => eachData.id == id) : null;
};

const isIos = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

const getOrganizationColor = (organization) => {
  return organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;
};

export {
  updateCookie,
  updateLocationInCookie,
  hhmmss,
  convertCurrencyFromText,
  rad,
  ToastZoomTransition,
  detectActiveSession,
  getMinimumAmount,
  getRates,
  isAuthenticated,
  getQueryString,
  generateCode,
  stringToBoolean,
  getDefaultPaymentMethod,
  isIos,
  getOrganizationColor,
  getRatesWithType,
};
