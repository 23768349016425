import React, { useEffect, useRef, useState } from 'react';
import punycode from 'punycode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { FaDollarSign, FaBusinessTime } from 'react-icons/fa';
import { IoStar, IoStarOutline } from 'react-icons/io5';
import {
  IoMailOutline,
  IoCallOutline,
  IoLinkOutline,
  IoLocationSharp,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { GrGallery } from 'react-icons/gr';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Text,
  NavigationIcon,
  Header,
  Footer,
  Loading,
  ConnectorCard,
  DetectSession,
  SignupButton,
  Root,
  NotFound,
  DetectLowBalance,
} from './../../components';
import { PRIMARY_COLOR } from './../../theme';
import './Chargestation.css';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';

//TODO remove icons and switch to react-ioncs library
import { CONNECTOR_STATUS } from './../../constants';
import { updateUserData } from './../../actions/user';
import CopyIcon from './../../assets/img/copy.svg';
import {
  updateCookie,
  ToastZoomTransition,
  detectActiveSession,
  convertCurrencyFromText,
  getMinimumAmount,
  getRates,
  getRatesWithType,
} from './../../utils/helper';
import Information from './Information';
import api from 'utils/api';
import { disableAccounts, disableWallets } from './../../config';
import { SESSION_TYPE } from './../../constants';
import RatesScheduleContainer from './RatesScheduleContainer';

const Chargestation = ({ organization, status, user, auth0User }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const { endpoint } = useParams();
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;
  const [chargestation, setChargestation] = useState({});
  const [images, setImages] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState({});
  const [cookies, setCookies] = useCookies();
  const [isActiveSession, setIsActiveSession] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isActiveSessionButtonText, setIsActiveSessionButtonText] = useState(
    'Not allowed due to existing pre-auth'
  );
  const [chargestationNotesModal, setChargestationNotesModal] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [customer, setCustomer] = useState({});
  const imagesRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [showDetailRate, setShowDetailRate] = useState(false);

  const modalToggle = () => {
    setChargestationNotesModal(!chargestationNotesModal);
  };
  useEffect(() => {
    const t = connectors?.map((eachConnector) => {
      window.location.pathname.includes(eachConnector.endpoint) &&
        setSelectedConnector(eachConnector);
    });
  }, [connectors]);

  const init = async () => {
    const status = await detectActiveSession({
      sessionId: cookies.store.sessionId,
    });

    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      dispatch(updateUserData(token));
    }

    if (Object.keys(status).length > 0) {
      setIsActiveSessionButtonText(`❌ Existing ${status.type} ❌`);
      setIsActiveSession(true);
    } else {
      setIsActiveSession(false);
    }

    fetchChargestation();

    if (!disableWallets()) {
      if (isAuthenticated) {
        let token = await getAccessTokenSilently();
        const response_wallet = await api.get(token, 'v1/wallet');
        setCustomer(response_wallet.result.customer);
      }
    }
  };

  const fetchChargestation = async () => {
    const response = await window.fetch(
      `${process.env.REACT_APP_API_URL}/v1/public/chargestations?static_endpoint=${endpoint}`
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      setChargestation(jsonResponse.result[0]);
      setImages(
        jsonResponse?.result[0]?.location?.images?.map((eachImage) => {
          return {
            original: eachImage.url,
            thumbnail: eachImage.url,
            description: eachImage.name,
          };
        })
      );
      const _connectors = jsonResponse.result[0]?.connectors ?? [];
      setConnectors(_connectors);
    } else {
      // setError(`Connector not found: ${endpoint}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
    localStorage.setItem('endpoint', window.location.pathname);
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      fetchChargestation();
    }, 10000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [endpoint]);

  const onClickFavorite = async () => {
    let token = await getAccessTokenSilently();
    let payload;
    let text;
    if (!user.favorite_chargestations) {
      payload = {
        favorite_chargestations: [chargestation._id],
      };
    } else {
      if (user.favorite_chargestations.indexOf(chargestation._id) > -1) {
        let _favorite_chargestations = [...user.favorite_chargestations];

        _favorite_chargestations.splice(
          user.favorite_chargestations.indexOf(chargestation._id),
          1
        );

        payload = {
          favorite_chargestations: _favorite_chargestations,
        };
        text = 'removed from';
      } else {
        let _favorite_chargestations = [
          ...user.favorite_chargestations,
          chargestation._id,
        ];

        payload = {
          favorite_chargestations: _favorite_chargestations,
        };

        text = 'added to';
      }
    }

    token = await getAccessTokenSilently();
    const res = await api.patch(token, `v1/users/${user._id}`, payload);

    toast.info(`Chargestation ${text} favorites`);
    token = await getAccessTokenSilently();
    dispatch(updateUserData(token));
  };

  const renderConnectorCard = (eachConnector, index) => {
    const isEachConnectorSelected = eachConnector._id === selectedConnector._id;
    const minimumAmount = getMinimumAmount(eachConnector.rate);
    return (
      <ConnectorCard
        connector={eachConnector}
        isSelected={isEachConnectorSelected}
        organizationColor={organizationColor}
        onClick={() => {
          setSelectedConnector(eachConnector);
        }}
        chargestation={chargestation}
        minAmount={`${convertCurrencyFromText(
          eachConnector?.rate?.currency ?? organization?.stripe_currency
        )} ${minimumAmount.amount} ${minimumAmount.type}`}
        id={`connector-${index}`}
      />
    );
  };

  const onClickPayNow = async () => {
    let token = null;
    setProcessing(true);
    let sessionId = null;
    if (isAuthenticated) {
      token = await getAccessTokenSilently();
      if (disableWallets()) {
        if (!user?.stripe_default_payment_method) {
          const response_session = await createSession(token, 'v1/sessions', {
            type: SESSION_TYPE.USER,
            chargestation: chargestation._id,
            connector: selectedConnector._id,
          });
          sessionId = response_session._id;
          history.push(`payment/${response_session.payment}`);
        } else {
          setLoadingPayment(true);

          const response_session = await createSession(token, 'v1/sessions', {
            type: SESSION_TYPE.PAYMENT_METHOD,
            chargestation: chargestation._id,
            connector: selectedConnector._id,
          });
          sessionId = response_session._id;
          const paymentId = response_session.payment;

          try {
            const response_payment = await api.post(
              token,
              `v1/payments/${paymentId}`,
              {
                customer_id: user?.stripe_id,
                payment_method_id: user.stripe_default_payment_method,
              }
            );

            history.replace(`/private/session/${sessionId}`, {
              from: 'payment',
            });
          } catch (e) {
            console.log(e);
            toast.info('API error');
          }
        }
      } else {
        if (
          parseFloat(-(customer.balance / 100)) >=
          (organization?.driver_application?.wallet?.minimum_balance
            ? organization?.driver_application?.wallet?.minimum_balance
            : organization.stripe_reserve_amount)
        ) {
          const response_session = await createSession(token, 'v1/sessions', {
            type: SESSION_TYPE.CUSTOM_BALANCE_TRANSACTION,
            chargestation: chargestation._id,
            connector: selectedConnector._id,
          });
          sessionId = response_session._id;
          history.push(`private/session/${sessionId}`);
        } else {
          history.push(`/wallet`);
        }
      }
    } else {
      const response_session = await createSession(
        token,
        'v1/public/sessions',
        {
          type: SESSION_TYPE.AD_HOC,
          chargestation: chargestation._id,
          connector: selectedConnector._id,
        }
      );
      sessionId = response_session._id;
      history.push(`payment/${response_session.payment}`);
    }

    updateCookie(
      {
        sessionId,
        endpoint,
      },
      cookies,
      setCookies
    );
    setProcessing(false);
  };

  const createSession = async (token, url, data) => {
    try {
      const response_session = await api.post(token, url, data);

      return response_session.result;
    } catch (e) {
      console.log(e);
      //TODO render error
      toast.info('API error');
    }
  };

  const renderPayNowAndStartButton = () => {
    let buttonText;

    if (disableWallets()) {
      if (user?.stripe_default_payment_method) {
        user.stripe_payment_methods.map((eachPaymentMethod) => {
          if (eachPaymentMethod.id === user?.stripe_default_payment_method) {
            buttonText = `Pay now with ${eachPaymentMethod?.card?.brand} ${eachPaymentMethod.card?.last4}`;
          }
        });
      } else {
        buttonText = 'Pay now and Start';
      }
    } else {
      if (isAuthenticated) {
        if (
          parseFloat(-(customer.balance / 100)).toFixed(2) >=
          (organization?.driver_application?.wallet?.minimum_balance
            ? organization?.driver_application?.wallet?.minimum_balance
            : organization.stripe_reserve_amount)
        ) {
          buttonText = 'Start Charging';
        } else {
          buttonText = 'Please Top Up wallet';
        }
      } else {
        buttonText = 'Pay now and Start';
      }
    }

    if (loadingPayment || processing) {
      buttonText = 'Connecting...';
    }

    return (
      <Button
        id="pay-now-and-start-button"
        className="action-button"
        style={{
          backgroundColor: organizationColor,
          borderColor: organizationColor,
        }}
        disabled={
          !(
            selectedConnector.status === CONNECTOR_STATUS.AVAILABLE ||
            selectedConnector.status === CONNECTOR_STATUS.PREPARING
          ) ||
          !chargestation.active ||
          !chargestation.online ||
          !chargestation.open_for_business ||
          isActiveSession ||
          loadingPayment ||
          processing
        }
        onClick={onClickPayNow}
      >
        {isActiveSession
          ? isActiveSessionButtonText
          : Object.keys(selectedConnector).length === 0
          ? 'Select a Connector'
          : connectors?.filter(
              (eachConnector) =>
                chargestation.active &&
                chargestation.online &&
                (chargestation?.open_for_business ?? true) &&
                (eachConnector.status === CONNECTOR_STATUS.AVAILABLE ||
                  eachConnector.status === CONNECTOR_STATUS.PREPARING)
            ).length > 0
          ? buttonText
          : chargestation.online
          ? '❌ Chargestation Unavailable ❌'
          : '❌ Chargestation offline ❌'}
      </Button>
    );
  };

  if (loading || endpoint === '') {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (!chargestation) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        // title="Transactions"
        showSupportChat={true}
      >
        <NotFound text="No Chargestation Found" />
      </Root>
    );
  }

  return (
    <>
      <div className="container">
        <DetectSession
          cookies={cookies}
          setCookies={setCookies}
          updateCookie={updateCookie}
        />
        <DetectLowBalance user={user} organization={organization} />
        <ToastContainer
          position="bottom-center"
          hideProgressBar
          autoClose={2500}
          transition={ToastZoomTransition()}
          closeButton={false}
        />
        <Header
          organization={organization}
          style={{ backgroundColor: 'white' }}
          user={user}
          auth0User={auth0User}
        />
        <div className="navigation-details">
          <IoLocationSharp className="location-logo" color="#98A1A9" />
          {/* <img className="location-logo" src={LocationIcon} /> */}
          <div className="location" id="location">
            <Text weight={700} size={16} id="location-line-1">
              {chargestation?.location?.address?.streetAndNumber}
            </Text>
            <Text weight={700} size={14} id="location-line-2">
              {chargestation?.location?.address?.city}
              {chargestation?.location?.address?.postalCode ? ', ' : ''}
              {chargestation?.location?.address?.postalCode}
              {chargestation?.location?.address?.state ? ', ' : ''}
              {chargestation?.location?.address?.state}
            </Text>
            {/* <Text weight={700} size={14}>
              {chargestation?.location?.address?.}
            </Text> */}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              const address = encodeURIComponent(
                chargestation?.location?.address?.streetAndNumber +
                  ' ' +
                  chargestation?.location?.address?.city +
                  ' ' +
                  chargestation?.location?.address?.postalCode
              );
              window.open(
                `http://maps.google.com/maps?daddr=${address}`,
                '_blank'
              );
            }}
          >
            <NavigationIcon
              pathColor={organizationColor}
              width={11}
              height={11}
            />
            &nbsp;
            {/* <img src={NavigationIcon} /> */}
            <Text weight={600} size={12} color={organizationColor}>
              Navigate
            </Text>
          </div>
        </div>
        {chargestation?.location?.notes || images.length > 0 ? (
          <div className="navigation-notes">
            <Text size={12} color="#98A1A9" weight={400} style={{ flex: 1 }}>
              {chargestation?.location?.notes}
            </Text>
            {chargestation?.location?.images.length > 0 ? (
              <GrGallery
                color="#98A1A9"
                onClick={() => {
                  imagesRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }}
              />
            ) : null}
          </div>
        ) : null}
        <div className="page-details">
          <IoLinkOutline style={{ marginRight: 6 }} color="#98A1A9" />
          <div className="page-url">
            <Text weight={200} size={14} opacity={0.5}>
              {organization?.custom_domain &&
                punycode.toUnicode(organization.custom_domain)}
              /
            </Text>
            <Text weight={600} size={14}>
              {endpoint}
            </Text>
          </div>
          <img
            className="copy-icon"
            src={CopyIcon}
            onClick={(e) => {
              navigator.clipboard
                .writeText(`https://${organization.custom_domain}/${endpoint}`)
                .then(() => {
                  toast.info('URL is copied to your clipboard');
                })
                .catch(() => {
                  toast.info('Error occured');
                });
            }}
          />
          {isAuthenticated ? (
            user?.favorite_chargestations?.indexOf(chargestation._id) > -1 ? (
              <IoStar
                size="1.2rem"
                color={organizationColor}
                onClick={onClickFavorite}
                id="favorite-chargestation-icon"
              />
            ) : (
              <IoStarOutline
                size="1.2rem"
                color={organizationColor}
                onClick={onClickFavorite}
                id="favorite-chargestation-icon"
              />
            )
          ) : null}
        </div>
        <div className="body">
          <div className="body-title">
            <Text weight={600} size={16}>
              Select a Connector &nbsp;
              {chargestation.notes ? (
                <IoInformationCircleOutline onClick={modalToggle} />
              ) : null}
            </Text>
            {chargestation &&
            connectors?.filter(
              (eachConnector) =>
                chargestation.active &&
                chargestation.online &&
                chargestation.open_for_business &&
                (eachConnector.status === CONNECTOR_STATUS.AVAILABLE ||
                  eachConnector.status === CONNECTOR_STATUS.PREPARING)
            ).length > 0 ? (
              <Text weight={400} size={12} color="#21A468">
                Available
              </Text>
            ) : chargestation.online ? (
              <Text weight={400} size={12} color="grey">
                Not Available
              </Text>
            ) : (
              <Text weight={400} size={12} color="grey">
                Offline
              </Text>
            )}
          </div>
          {connectors
            ?.filter((eachConnector) => eachConnector.rate)
            .map((eachConnector, index) =>
              renderConnectorCard(eachConnector, index)
            )}
          <div className="action-button-container">
            <div>{renderPayNowAndStartButton()}</div>
            <div className="action-button-text">
              <Text size={12} color="#98A1A9" weight={400}>
                An amount of{' '}
                {convertCurrencyFromText(
                  selectedConnector?.rate?.currency ??
                    organization.stripe_currency
                )}{' '}
                {organization.stripe_reserve_amount} will be pre-authorized on
                your credit card. Any unused amount will be credited back to
                your account at the end of the charging session.
              </Text>
            </div>
          </div>

          <div className="connector-additional-detail-container">
            <div className="tariff-container">
              <div className="information-icon">
                <FaDollarSign size={20} />
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                id="rate-container"
              >
                <Text
                  size={14}
                  weight={400}
                  style={{
                    flex: 1,
                  }}
                >
                  Rate
                </Text>
                {selectedConnector?.rate?.type === 'schedule' ? (
                  showDetailRate ? (
                    <>
                      <Text onClick={() => setShowDetailRate(false)}>
                        {' '}
                        Close &nbsp;{' '}
                      </Text>
                      <IoChevronUp onClick={() => setShowDetailRate(false)} />
                    </>
                  ) : (
                    <>
                      <Text onClick={() => setShowDetailRate(true)}>
                        {' '}
                        Expand &nbsp;{' '}
                      </Text>
                      <IoChevronDown onClick={() => setShowDetailRate(true)} />
                    </>
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}
                    id="rate-value"
                  >
                    {!selectedConnector?.rate?.alt_text ? (
                      <Text weight={600} size={14}>
                        -
                      </Text>
                    ) : null}

                    {getRates(selectedConnector?.rate).map(
                      (eachRate, index) => (
                        <Text
                          weight={index == 0 ? 600 : 400}
                          size={index == 0 ? 14 : 12}
                        >
                          {eachRate}
                        </Text>
                      )
                    )}

                    {selectedConnector?.rate?.alt_text && (
                      <Text size={12} weight={400} color="#98A1A9">
                        {` ${convertCurrencyFromText(
                          selectedConnector?.rate?.currency ??
                            organization?.stripe_currency
                        )} ${
                          getMinimumAmount(selectedConnector?.rate)?.amount
                        } ${getMinimumAmount(selectedConnector?.rate)?.type}`}
                      </Text>
                    )}
                  </div>
                )}
              </div>
            </div>
            {selectedConnector?.rate?.type === 'schedule' ? (
              <div style={{ backgroundColor: 'white' }}>
                {showDetailRate ? (
                  <RatesScheduleContainer
                    rate={selectedConnector?.rate}
                    organizationColor={organizationColor}
                  />
                ) : null}
              </div>
            ) : null}

            {/* <Information
              Icon={IoTimeOutline}
              type="Available"
              value="00:00 - 23:59"
            /> */}
            {organization?.support?.email && (
              <Information
                Icon={IoMailOutline}
                type="Support Email"
                value={organization?.support?.email}
                href={`mailto:${organization?.support?.email}`}
                color={organizationColor}
              />
            )}
            {organization?.support?.contact_phone_number && (
              <Information
                Icon={IoCallOutline}
                type="Support Phone"
                value={organization?.support?.contact_phone_number}
                href={`tel:${organization?.support?.contact_phone_number}`}
                color={organizationColor}
              />
            )}
            {organization?.support?.business_hours && (
              <Information
                Icon={FaBusinessTime}
                type="Support Hours"
                value={organization?.support?.business_hours}
              />
            )}
          </div>
        </div>
        <SignupButton
          organization={organization}
          style={{ borderTop: '1px solid #ddd' }}
          show={!disableAccounts()}
        />
        {images.length > 0 ? (
          <>
            <div style={{ padding: '12px 16px' }}>
              <Text weight={600} size={16}>
                Photos
              </Text>
            </div>
            <div ref={imagesRef}>
              <ImageGallery items={images} showPlayButton={false} />
            </div>
          </>
        ) : null}
        <Footer organization={organization} showSupportChat={true} />
        <Modal isOpen={chargestationNotesModal} toggle={modalToggle}>
          <ModalHeader toggle={modalToggle}>
            <Text size={16}>Chargestation Notes</Text>
          </ModalHeader>
          <ModalBody>
            <Text size={14}>{chargestation.notes}</Text>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Chargestation);
