import React, { useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useAuth0 } from '@auth0/auth0-react';

import api from 'utils/api';
import { PRIMARY_COLOR } from 'theme';
import PoweredByStripeIcon from './../../assets/img/powered-by-stripe-blurple.svg';
import { Text, Button, SignupButton, NotFound, Header } from '../../components';
import {
  convertCurrencyFromText,
  updateCookie,
  ToastZoomTransition,
} from './../../utils/helper';
import {
  FormHeader,
  FormMessage,
  FormBody,
  FormFooter,
  Box,
  EmailInput,
  CreditCardContainer,
  CreditCardNumberContainer,
  CreditCardExpiryDateContainer,
  PoweredByContainer,
} from './StyledComponents';
import { disableAccounts } from 'config';

const PaymentForm = ({
  organization,
  location,
  isActiveSession,
  isActiveSessionButtonText,
  user,
  auth0User,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;
  const history = useHistory();
  const [cookies, setCookies] = useCookies();
  const stripeReserveAmount = organization?.stripe_reserve_amount ?? 5;
  const [stripeCurrency, setStripeCurrency] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const { payment_id } = useParams();
  const [sessionId, setSessionId] = useState('');
  const [validPaymentId, setValidPaymentId] = useState(true);
  const [receiptEmail, setReceiptEmail] = useState(user?.email ?? null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [errorText, setErrorText] = useState('');
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [saveCreditCard, setSaveCreditCard] = useState(true);
  const [showCreditCardInformation, setShowCreditCardInformation] =
    useState(false);
  const [cancelCommandLoading, setCancelCommandLoading] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (user.email) {
      setReceiptEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (stripe && clientSecret && stripeCurrency) {
      const pr = stripe.paymentRequest({
        country: organization?.stripe_country?.toUpperCase() ?? 'NL',
        currency: stripeCurrency.toLowerCase(),
        total: {
          label: '',
          amount: stripeReserveAmount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          // pr['_authentication']['accountId'] =
          //   organization.stripe_connected_account_id;
          setPaymentRequest(pr);
        } else {
          console.log('ERROR');
        }
      });
    }
  }, [stripe, clientSecret, stripeCurrency]);

  useEffect(() => {
    clientSecret &&
      paymentRequest &&
      paymentRequest.on('paymentmethod', async (ev) => {
        if (isActiveSession) {
          toast.info(
            'You are already running a transaction. Can not start a new one.'
          );
        } else {
          setPaymentProcessing(true);
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
            setErrorText(confirmError.message);
            setPaymentProcessing(false);
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11" instead
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                setErrorText('Please select a different payment method');
                setPaymentProcessing(false);
              } else {
                // The payment has succeeded.
                updateCookie(
                  {
                    sessionId,
                    endpoint: location?.state?.endpoint,
                  },
                  cookies,
                  setCookies
                );
                toast.info(
                  'Your pre-auth payment has been processed. An email receipt has been sent to you.'
                );
                history.replace(`/private/session/${sessionId}`, {
                  from: 'payment',
                });
                setPaymentProcessing(false);
              }
            } else {
              // The payment has succeeded.
              updateCookie(
                {
                  sessionId,
                  endpoint: location?.state?.endpoint,
                },
                cookies,
                setCookies
              );
              toast.info(
                'Your pre-auth payment has been processed. An email receipt has been sent to you.'
              );
              history.replace(`/private/session/${sessionId}`, {
                from: 'payment',
              });
              setPaymentProcessing(false);
            }
          }
        }
      });
  }, [paymentRequest, clientSecret]);

  const init = async () => {
    let token;
    let url;
    if (isAuthenticated) {
      token = await getAccessTokenSilently();
      url = `v1/payments/${payment_id}`;
    } else {
      url = `v1/public/payments/${payment_id}`;
    }

    if (payment_id) {
      try {
        // const query = customer_id:
        const response_payment = await api.post(token, url, {
          customer_id: user?.stripe_id,
        });

        setClientSecret(response_payment.result?.client_secret);
        setStripeCurrency(
          response_payment.result?.currency ??
            organization?.stripe_currency ??
            'usd'
        );
        setSessionId(response_payment.result?.session_id);

        if (!response_payment.result.client_secret) {
          setValidPaymentId(false);
        }
      } catch (e) {
        //TODO render error
        setErrorText('API error');
        console.log(e);
      }
    }
  };

  const onClickCancel = async (event) => {
    event.preventDefault();
    setCancelCommandLoading(true);

    let token;
    let url;

    try {
      // if (isAuthenticated) {
      //   token = await getAccessTokenSilently();
      //   url = `v1/sessions/${sessionId}/cancel`;
      // } else {
      url = `v1/public/sessions/${sessionId}/cancel`;
      // }

      const response_session_cancel = await api.get(token, url);

      if (!response_session_cancel.ok) {
        toast.info(response_session_cancel.message);
      }
    } catch (e) {
      console.log('error');
      // setRenderData(RENDER_DATA_PAYMENT_INTENT_FAIL);
    }
    await init();
    setCancelCommandLoading(false);
  };

  const handleSubmit = async (e) => {
    setPaymentProcessing(true);
    e.preventDefault();
    var cardNumberElement = elements.getElement(CardNumberElement);

    if (stripe) {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              email: receiptEmail,
            },
          },
          setup_future_usage:
            saveCreditCard && user?.stripe_id ? 'off_session' : '',
        }
      );

      if (error) {
        setErrorText(error.message);
      } else {
        updateCookie(
          {
            sessionId,
            endpoint: location.state?.endpoint,
          },
          cookies,
          setCookies
        );
        history.replace(`/private/session/${sessionId}`, {
          from: 'payment',
        });
      }
      setPaymentProcessing(false);
    } else {
      setPaymentProcessing(false);
    }
  };

  if (!validPaymentId) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '85vh',
            backgroundColor: '#F6F6F6',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px 40px',
          }}
        >
          <Text
            id="cancel-charging-text"
            size={20}
            style={{ textAlign: 'center' }}
          >
            Payment Session Expired.
          </Text>
          <br />
          <br />
          <Button
            id="go-back"
            className="action-button"
            backgroundColor={organizationColor}
            style={{
              color: 'white',
              borderColor: organizationColor,
            }}
            containerStyle={{
              width: '70%',
            }}
            onClick={() => {
              history.goBack();
            }}
            buttonText="Go Back"
            fontStyle={{
              size: 18,
              fontWeight: 600,
            }}
            fontColor="white"
          ></Button>
        </div>
      </>
    );
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        autoClose={2500}
        transition={ToastZoomTransition()}
        closeButton={false}
      />
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            //TODO adjust height, test different devices
            minHeight: '85vh',
          }}
        >
          <FormHeader>
            <img height="75" width="75" src={organization?.logo} />
            <Text size={16} weight="600">
              {organization?.name}
            </Text>
          </FormHeader>
          <FormMessage>
            {
              <Text size={12}>{`An amount of ${convertCurrencyFromText(
                stripeCurrency
              )} ${stripeReserveAmount} will be pre-authorized on your credit card. Any unused amount will be credited back to your account at the end of the charging session.`}</Text>
            }
          </FormMessage>
          <SignupButton
            organization={organization}
            style={{
              border: `1px solid ${organizationColor}`,
              padding: 8,
              margin: '8px 28px',
              borderRadius: 4,
            }}
            textStyle={{ color: organizationColor, size: '14px' }}
            buttonText="Create an account now"
            onClick={() => {
              updateCookie({}, cookies, setCookies);
            }}
            show={!disableAccounts()}
          />

          <br />
          {paymentRequest ? (
            <div style={{ margin: '0 28px' }}>
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      theme: 'dark',
                      height: '40px',
                    },
                  },
                }}
              />
            </div>
          ) : null}
          <Button
            id="showCreditCardDetailForm"
            buttonText={'Pay for Single Session via Credit Card'}
            backgroundColor={
              !showCreditCardInformation ? organizationColor : '#FAFAFA'
            }
            onClick={() => {
              setShowCreditCardInformation(!showCreditCardInformation);
            }}
            processing={false}
            fontColor="white"
            containerStyle={{
              margin: '8px 28px',
              border: showCreditCardInformation
                ? `1px solid ${organizationColor}`
                : '',
              borderRadius: 4,
            }}
            fontStyle={{
              fontWeight: 600,
              color: showCreditCardInformation ? organizationColor : 'white',
            }}
            // show={!showCreditCardInformation}
          />
          <Button
            id="cancel-payment"
            backgroundColor="#DDD"
            processing={cancelCommandLoading}
            containerStyle={{
              margin: '8px 28px',
              borderRadius: 8,
            }}
            fontStyle={{
              fontWeight: 600,
            }}
            buttonText="Cancel"
            onClick={onClickCancel}
          />
          {showCreditCardInformation ? (
            <FormBody>
              <>
                {paymentRequest ? (
                  <div style={{ margin: 16, textAlign: 'center' }}>
                    <Text>
                      Pay via Apple, Google Pay above or enter your credit card
                      details below.
                    </Text>
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 16,
                  }}
                >
                  <Text size={12} style={{ marginLeft: 4 }}>
                    Email
                  </Text>
                  <Box>
                    <EmailInput
                      name="email"
                      type="email"
                      value={receiptEmail}
                      onChange={(e) => setReceiptEmail(e.target.value)}
                      placeholder="jane.doe@example.com"
                      required
                      id="email"
                    />
                  </Box>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <Text size={12}>Card Information</Text>
                </div>
                <CreditCardContainer>
                  <CreditCardNumberContainer>
                    <CardNumberElement
                      {...createOptions()}
                      style={{
                        padding: 8,
                        margin: 8,
                        backgroundColor: 'cyan',
                      }}
                      id="credit-card-number"
                    />
                  </CreditCardNumberContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <CreditCardExpiryDateContainer>
                      <CardExpiryElement
                        {...createOptions()}
                        id="credit-card-expiry-date"
                      />
                    </CreditCardExpiryDateContainer>
                    <div
                      style={{
                        padding: 16,
                        flex: 1,
                      }}
                    >
                      <CardCvcElement
                        {...createOptions()}
                        id="credit-card-cvc"
                      />
                    </div>
                  </div>
                </CreditCardContainer>
                <div style={{ textAlign: 'center' }}>
                  {errorText ? (
                    <Text size={16} color="#eb1c26">
                      {errorText}
                    </Text>
                  ) : (
                    ''
                  )}
                </div>
                {user?.stripe_id ? (
                  <div
                    style={{
                      marginLeft: 16,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      id="save-credit-card"
                      type="checkbox"
                      defaultChecked={saveCreditCard}
                      onChange={() => setSaveCreditCard(!saveCreditCard)}
                      checked={saveCreditCard}
                    />
                    <Text
                      style={{ marginLeft: 8 }}
                      size="12"
                      onClick={() => {
                        setSaveCreditCard(!saveCreditCard);
                      }}
                    >
                      Save this credit card for future transactions.
                    </Text>
                  </div>
                ) : null}
                <FormFooter
                  id="submit"
                  disabled={
                    paymentProcessing ||
                    isActiveSession ||
                    !receiptEmail ||
                    cancelCommandLoading
                  }
                  onClick={handleSubmit}
                  style={{ backgroundColor: organizationColor }}
                >
                  <Text style={{ color: 'white' }} weight="600">
                    {isActiveSession
                      ? isActiveSessionButtonText
                      : paymentProcessing
                      ? 'Processing...'
                      : `Pre-Authorize ${convertCurrencyFromText(
                          stripeCurrency
                        )} ${stripeReserveAmount}`}
                  </Text>
                </FormFooter>
                <div style={{ textAlign: 'end', marginRight: 16 }}>
                  <Text size={10}>
                    See{' '}
                    {organization?.links?.privacy ? (
                      <a
                        style={{ color: '#5e72e4' }}
                        href={organization?.links?.privacy ?? ''}
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>
                    ) : (
                      'Terms and Conditions'
                    )}
                  </Text>
                </div>
              </>
            </FormBody>
          ) : null}
        </div>
        <br />
        {showCreditCardInformation ? (
          <PoweredByContainer>
            <img
              src={PoweredByStripeIcon}
              style={{ width: '70%' }}
              height="30"
            />
          </PoweredByContainer>
        ) : null}
        <br />
      </form>
    </>
  );
};

const createOptions = () => {
  return {
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

export default PaymentForm;
