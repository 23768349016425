import React from 'react';

import { CONNECTOR_STATUS } from './../../constants';
import Text from './../Text/Text';
import ConnectorIcon from './../ConnectorIcon/ConnectorIcon';
import './Connector.css';
import { getRates } from 'utils/helper';

const Connector = ({
  connector,
  isSelected,
  organizationColor,
  onClick,
  chargestation = {},
  minAmount,
  id,
}) => {
  return (
    <div
      className={'connector-container ' + (isSelected ? 'selected' : '')}
      style={{
        backgroundColor: isSelected ? `${organizationColor}10` : 'white',
        borderColor: isSelected ? organizationColor : null,
      }}
      onClick={onClick}
      id={id}
    >
      <div
        className={
          connector.status === CONNECTOR_STATUS.AVAILABLE &&
          chargestation.online
            ? 'connector-status-live'
            : 'connector-status-dead'
        }
      ></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ConnectorIcon type={connector?.type} color={organizationColor} />
      </div>
      <div className="connector-detail">
        <div className="connector-detail-1">
          <Text
            weight={600}
            size={16}
            color={organizationColor}
            style={{ marginTop: 4 }}
          >
            {connector?.power} kW
          </Text>
          <Text weight={400} size={12}>
            {connector?.type}
          </Text>
        </div>
        <div className="connector-detail-2">
          <Text weight={400} size={10} color={organizationColor}>
            {connector?.power_type?.includes('AC') ? 'AC' : 'DC'}
          </Text>
          <Text weight={400} size={12}>
            {getRates(connector?.rate)[0]}
          </Text>
          {connector?.rate?.type !== 'schedule' ? (
            minAmount ? (
              <Text weight={400} size={12}>
                {minAmount}
              </Text>
            ) : (
              <Text weight={400} size={12}>
                {connector?.rate?.alt_text[0].text.split('+')[1]
                  ? `${connector?.rate?.alt_text[0].text.split('+')[1]}`
                  : ''}
              </Text>
            )
          ) : (
            <Text weight={400} size={12}>
              See detailed rate below
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default Connector;
