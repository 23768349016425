import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { PRIMARY_COLOR } from 'theme';
import api from 'utils/api';
import Text from '../Text/Text';
import ChevronForwardOutlineIcon from './../Icon/ChevronForwardOutline';
import CloseOutlineIcon from './../Icon/CloseOutline';
import { convertCurrencyFromText } from './../../utils/helper';
import {
  PAYMENT_STATUS,
  SESSION_STATUS,
  SESSION_TYPE,
} from './../../constants';

const Session = ({
  organization,
  cookies,
  setCookies,
  updateCookie,
  redirect = false,
}) => {
  const history = useHistory();
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  const { isAuthenticated } = useAuth0();
  const [showDialog, setShowDialog] = useState(false);
  const [renderData, setRenderData] = useState({
    title: '',
    detail: [
      {
        key: 'Station:',
        value: '',
      },
      {
        key: 'Started:',
        value: '',
      },
    ],
  });
  const [sessionData, setSessionData] = useState({});

  useEffect(() => {
    if (cookies.store.sessionId) {
      init(cookies.store.sessionId);
    }
  }, []);

  const init = async (sessionId) => {
    const response_session = await api.get(
      null,
      `v1/public/sessions/${sessionId}`
    );

    const session = response_session.result;
    const transaction = session?.transaction;
    const chargestation = session?.chargestation;
    const payment = session?.payment;

    setSessionData(session);
    if (session.type === SESSION_TYPE.CUSTOM_BALANCE_TRANSACTION) {
      if (
        session &&
        session?.status !== SESSION_STATUS.ENDED &&
        session?.status !== SESSION_STATUS.CANCELED
      ) {
        setRenderData({
          ...renderData,
          title: 'Ongoing Charging Session',
          detail: [
            {
              key: 'Station:',
              value: chargestation?.static_endpoint ?? cookies?.store?.endpoint,
            },
            {
              key: 'Started:',
              value: moment(transaction?.createdAt).format(
                'DD MMM YYYY hh:mm A'
              ),
            },
          ],
        });
        setShowDialog(true);
      } else {
        updateCookie({}, cookies, setCookies);
      }
    } else {
      if (transaction && transaction?.status !== 'Ended') {
        setRenderData({
          ...renderData,
          title: 'Ongoing Charging Session',
          detail: [
            {
              key: 'Station:',
              value: chargestation?.static_endpoint ?? cookies?.store?.endpoint,
            },
            {
              key: 'Started:',
              value: moment(transaction?.createdAt).format(
                'DD MMM YYYY hh:mm A'
              ),
            },
          ],
        });
        setShowDialog(true);
      } else if (
        payment?.status === 'requires_capture' &&
        // session.type === 'ad_hoc' &&
        !isAuthenticated
      ) {
        setRenderData({
          ...renderData,
          title: `${convertCurrencyFromText(
            payment?.currency ?? organization?.stripe_currency
          )} ${organization.stripe_reserve_amount} Pre-Authorized`,
          detail: [
            {
              key: 'Station:',
              value: chargestation?.static_endpoint ?? cookies?.store?.endpoint,
            },
            {
              key: '',
              value:
                'Pre-Auth amounts are returned within 5 minutes if charging does not start',
            },
          ],
        });
        setShowDialog(true);
      } else if (
        payment?.status === 'requires_payment_method' &&
        // session.type === 'ad_hoc' &&
        !isAuthenticated
      ) {
        setRenderData({
          ...renderData,
          title: `Please Pre-Authorize ${convertCurrencyFromText(
            payment?.currency ?? organization?.stripe_currency
          )} ${organization.stripe_reserve_amount}`,
          detail: [
            {
              key: 'Station:',
              value: chargestation?.static_endpoint ?? cookies?.store?.endpoint,
            },
            {
              key: '',
              value:
                'Pre-Auth amounts are returned within 5 minutes if charging does not start',
            },
          ],
        });
        setShowDialog(true);
      } else {
        updateCookie({ sessionId: null, paymentId: null }, cookies, setCookies);
      }
    }
  };

  const continueSession = () => {
    if (sessionData.payment.status === PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD) {
      history.push(`payment/${sessionData.payment._id}`);
    } else {
      history.push(`/private/session/${cookies.store.sessionId}`);
    }
  };

  if (!showDialog) {
    return null;
  } else {
    return (
      <Container color={organizationColor} onClick={continueSession}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div>
            <Text color="white" size={16}>
              {renderData.title}
            </Text>
          </div>
          {renderData?.detail.map((eachDetail) => (
            <div>
              <Text color="white" size={12}>
                {eachDetail.key}
                <Text
                  color="white"
                  size={14}
                  style={{ paddingLeft: eachDetail.key ? 14 : 0 }}
                >
                  {eachDetail.value}
                </Text>
              </Text>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: -8, marginLeft: 16 }}>
            <CloseOutlineIcon
              height="22"
              style={{ color: 'white' }}
              onClick={(e) => {
                setShowDialog(false);
                e.stopPropagation();
              }}
            />
          </div>
          <ChevronForwardOutlineIcon
            height="24"
            style={{
              color: 'white',
              flex: 1,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          />
        </div>
      </Container>
    );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 9999;
  width: 96%;
  background-color: ${(props) => props.color};
  margin: 2%;
  padding: 16px;
  border-radius: 8px;
`;

const Button = styled.button`
  flex: 1;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 4px;
  margin: 4px;
  padding: 8px;
  color: white;
`;

const mapStateToProps = (state) => ({
  organization: state.organization,
});

export default connect(mapStateToProps)(Session);
